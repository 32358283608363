<template>
  <div class="not-auth">
    <van-empty description="无此页面访问权限，请联系管理员" :image="img403">
      <van-button round class="not-auth__bottom-button" @click="$router.back()"
        >返回</van-button
      >
    </van-empty>
  </div>
</template>

<script>
import img403 from "@/assets/images/403.png";
export default {
  data() {
    return {
      img403
    };
  }
};
</script>

<style lang="scss">
.not-auth {
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .van-empty__bottom {
    margin-top: 32px;
  }
  &__bottom-button {
    width: 92px;
    height: 32px;
    line-height: 32px;
    border: 1px solid #1676ff;
    border-radius: 16px;
    color: #0072ff;
    font-size: 14px;
  }
}
</style>
